<template>
  <el-dialog
    :title="title"
    :visible.sync="dialogVisible"
    @close="close"
    :close-on-click-modal="false"
  >
    <div class="client-desc-type" v-if="dialogVisible">
      <div class="client-desc-main">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane
            :label="item.name"
            :name="item.label"
            v-for="item of options"
            :key="item.label"
          ></el-tab-pane>
        </el-tabs>
        <!-- <keep-alive> -->
        <component
          :is="currentComponent"
          :tabColumn="column"
          :lastDicts="lastDicts"
          @cancel="close"
          @close="close"
        ></component>
        <!-- </keep-alive> -->
      </div>
    </div>
  </el-dialog>
</template>
<script>
import myTab1 from "../../clientType/myTab1.vue";
import myTab2 from "../../clientType/myTab2.vue";
import myTab3 from "../../clientType/myTab3.vue";
import myTab4 from "../../clientType/myTab4.vue";
import { mapState } from "vuex";
import mixinsI18n from "@/mixins/i18n.js"
export default {
  name: "desc-client",
  mixins: [mixinsI18n],
  components: { myTab1, myTab2, myTab3, myTab4 },
  props: {
    title: String,
  },
  inject: ["lastDicts"],
  data: () => {
    return {
      dialogVisible: false,
      activeName: "1",
      currentComponent: "myTab1",
      // dicts: {
      //   networkingMode: [],
      //   protocol: [],
      //   accessType: [],
      //   dataType: [],
      // },
      PAGE_NAME: "clientTypePage",
    };
  },
  computed: {
    ...mapState({ row: (state) => state.clientType.row }),
    options() {
      let tab = [{ label: "1", name: this.lang.typeDetail }];
      if (
        String(this.row.protocol).split("_")[1] === "1" ||
        this.row.protocol === "MQTT"
      ) {
        tab.push({ label: "2", name: this.lang.mqtt });
      }
      if (String(this.row.dataType).split("_")[1] === "2") {
        tab.push({ label: "3", name: this.lang.customize });
      }
      if (String(this.row.dataType).split("_")[1] === "1") {
        tab.push({ label: "4", name: this.lang.paraCon });
      }
      tab = Array.from(new Set(tab));
      return tab;
    },
    column() {
      const dict = this.lastDicts;
      const tabs = [
        {
          tag: "div",
          title: this.lang.property,
          cls: [
            {
              label: this.lang.terType,
              tag: "el-input",
              prop: "deviceTypeName",
              required: true,
              rules: [
                { max: 20, message: this.lang.max20, trigger: "blur" },
              ],
            },
            {
              tag: "el-input",
              label: this.lang.category,
              prop: "typeClass",
              rules: [
                { max: 30, message: this.lang.max30, trigger: "blur" },
              ],
            },
            {
              tag: "el-select",
              label: this.lang.netMode,
              required: true,
              prop: "networkingMode",
              cls: dict.networkingMode,
            },
            {
              tag: "el-select",
              label: this.lang.proType,
              required: true,
              prop: "protocol",
              cls: dict.protocol,
            },
            {
              tag: "el-select",
              label: this.lang.auMode,
              required: true,
              prop: "accessType",
              cls: dict.accessType,
            },
            {
              tag: "el-select",
              label: this.lang.dataFormat,
              required: true,
              prop: "dataType",
              cls: dict.dataType,
            },
            {
              label: this.lang.describe,
              tag: "el-input",
              prop: "describe",
              type: "textarea",
              rules: [
                { max: 50, message: this.lang.max50, trigger: "blur" },
              ],
            },
          ],
        },
      ];
      return tabs;
    },
  },
  methods: {
    handleClick(row) {
      this.activeName = String(parseInt(row.name));
      const n = parseInt(row.name);
      this.currentComponent = `myTab${n}`;
    },
    close() {
      this.activeName = "1";
      this.currentComponent = "myTab1";
      this.dialogVisible = false;
      this.$emit("isClose");
    },
  },
};
</script>
<style scoped lang="scss">
::v-deep .el-dialog__body {
  padding: 10px;
}
.client-desc-type {
  margin: 20px;
  .client-desc-main {
    margin-top: 20px;
    background-color: #fff;
  }
}
</style>
