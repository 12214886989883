<template>
  <div class="client-myTab3">
    <!-- <el-input
      type="textarea"
      v-model="text"
      v-if="!isShow"
      :rows="20"
    ></el-input> -->
    <els-monaco-editor
      style="height: 300px; border: 1px solid #ddd; margin-top: 10px"
      :showHeader="false"
      theme="vs-light"
      ref="editor"
      v-model="text"
      :disabled="isShow"
      lang="xml"
    ></els-monaco-editor>
    <!-- <div v-else class="text-main" style="height: 400px">{{ text }}</div> -->
    <div class="footer">
      <el-button type="primary" v-if="isShow" @click="edit">
        {{$t('d.edit')}}
      </el-button>
      <div class="tab-footer" v-if="!isShow">
        <el-button @click="cancel">{{$t('d.cancel')}}</el-button>
        <el-button type="primary" @click="changeEdit" :loading="isSure">
          {{$t('d.save')}}
        </el-button>
      </div>
    </div>
  </div>
</template>
<script>
import elsMonacoEditor from "../../../components/els-monaco-editor/main.vue";
import { mapState } from "vuex";
import {
  getFeatureSet,
  saveFeatureSet,
} from "@/api/manager/client-type-manage.js";
export default {
  name: "client-tab3",
  components: {
    elsMonacoEditor,
  },
  data: () => {
    return {
      isSure: false,
      text: "",
      isShow: true,
      cText: "",
    };
  },
  computed: {
    ...mapState("clientType", {
      row(state) {
        return state.row;
      },
    }),
  },
  watch: {
    row(v) {
      this.init();
    },
    text(t) {
      // console.log("text-tt", t);
      // console.log(typeof t);
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    async init() {
      const d = {
        deviceTypeId: this.row.id || this.row.id.id,
      };
      const res = await getFeatureSet({ ...d });
      if (res) {
        this.text = res;
        this.cText = res;
      }
    },
    // 编辑
    edit(v) {
      this.isShow = !this.isShow;
    },
    async changeEdit() {
      this.isSure = true;
      this.isShow = !this.isShow;
      const d = {
        deviceTypeId: this.row.id || this.row.id.id,
        // featureSet: JSON.stringify(this.text),
        featureSet: this.text,
      };
      if (this.isShow) {
        setTimeout(() => {
          this.isSure = false;
        }, 2000);
        const res = await saveFeatureSet({ ...d });
        if (res) {
          this.cText = res;
          this.$message({
            message: this.$t('d.saveSuccess'),
            type: "success",
          });
        }
      }
    },
    cancel() {
      this.isShow = !this.isShow;
      this.text = this.cText;
    },
  },
};
</script>
<style scoped lang='scss'>
.client-myTab3 {
  .text-main {
    border: 1px solid #ddd;
    padding: 20px;
    overflow-y: auto;
  }
  .footer {
    margin-top: 20px;
    text-align: right;
  }
}
</style>
