<template>
    <div class="client-myTab4">
        <div class="client-header">
            <el-button type="primary" @click="handleAdd">{{ lang.addPara }}</el-button>
            <el-button type="primary" @click="handleParamClass">{{ lang.paraClassMain }}</el-button>
        </div>
        <div class="client-main">
            <els-table ref="tableRefs" style="height: 400px" :column="tableColumn" :onload="tableOnload" menuWidth="110px">
                <template v-slot:menu="{ row }">
                    <el-button type="text" @click.stop="handleRowEdit(row)">{{ lang.edit }}
                    </el-button>
                    <el-divider direction="vertical"></el-divider>
                    <el-button type="text" @click.stop="handRowDel(row)">{{ lang.delete }}</el-button>
                </template>
            </els-table>
            <div class="footer">
                <el-button @click="close">{{ $t('d.close') }}</el-button>
            </div>
        </div>
        <els-form-dialog :visible.sync="dialogVisible" :title="dialogTitle" width="540px" :loading="loadingBtn"
            :cancel="lang.cancel" :submit="lang.confirm" @submit="handleSubmit">
            <els-form :ctx="{ lang: $t('d') }" v-if="dialogVisible" ref="elsFormRef" v-model="formData"
                :column="formColumn">
            </els-form>
        </els-form-dialog>
        <els-form-dialog :visible.sync="classDialogVisible" :title="classDialogTitle" width="540px" :loading="loadingBtn"
            :showFooter="false">
            <els-form :ctx="{ lang: $t('d') }" v-if="classDialogVisible" ref="classFormRef" v-model="classFormData"
                :column="classFormColumn">
            </els-form>
            <div class="class-dialog-footer">
                <el-button @click="classDialogVisible = false" class="btn-normal">{{ lang.close }}</el-button>
            </div>
        </els-form-dialog>
    </div>
</template>

<script>
import elsTable from "@/components/els-table/main.vue";
import elsFormDialog from "@/components/els-form-dialog/main.vue";
import elsForm from "@/components/els-form/main.vue";
import mixinsI18n from "@/mixins/i18n.js";
import { mapState } from "vuex";
import {
    getDeviceTypeNode,
    addDeviceTypeNode,
    updateDeviceTypeNode,
    delDeviceTypeNode,
    getClassDataList,
    addParamClass,
    updateParamClass,
    delParamClass
} from "@/api/manager/client-type-manage.js";
import { getDict } from "@/api/com/iot-dict.js";

export default {
    name: "myTab4",
    mixins: [mixinsI18n],
    components: { elsTable, elsFormDialog, elsForm },
    data: () => {
        return {
            PAGE_NAME: "clientTypePage",
            dialogTitle: "",
            loadingBtn: false,
            formData: {},
            dialogVisible: false,
            nodeClassDict: [],
            classDialogTitle: "",
            classDialogVisible: false,
            classFormData: {},
            classFormDataLimit: 0,
        }
    },
    computed: {
        ...mapState("clientType", {
            row(state) {
                return state.row;
            }
        }),
        tableColumn() {
            const name = { prop: "nameEn", label: this.lang.paraName };
            const des = { prop: "nameCn", label: this.lang.paraDes };
            const unit = { prop: "unit", label: this.lang.unit };
            const type = { prop: "nodeClass", label: this.lang.paraClass, dict: this.nodeClassDict };
            return [name, des, unit, type]
        },
        formColumn() {
            const name = {
                prop: "nameEn",
                label: this.lang.paraName,
                required: true,
                rules: [
                    { max: 20, message: this.lang.max20, trigger: "blur" },
                ],
            };
            const des = {
                prop: "nameCn",
                label: this.lang.paraDes,
                required: true,
                rules: [
                    { max: 30, message: this.lang.max30, trigger: "blur" },
                ],
            };
            const unit = {
                prop: "unit",
                label: this.lang.unit,
                rules: [
                    { max: 20, message: this.lang.max20, trigger: "blur" },
                ],
            };
            const type = {
                tag: "el-select",
                prop: "nodeClass",
                label: this.lang.paraClass,
                required: true,
                trigger: "change",
                cls: this.nodeClassDict
            };
            return [name, des, unit, type]
        },
        classFormColumn() {
            const table = {
                tag: "item-table",
                prop: "class",
                showMenu: true,
                required: true,
                autoEdit: false,
                hasRequest: true,
                beforeRequest: this.handleRow,
                limit: this.classFormDataLimit,
                limitMessage: this.lang.currentLine,
                class: "type-item-table",
                initLine: 0,
                rules: [{
                    name: [{ required: true, message: this.lang.cannotEmpty }, { max: 20, message: this.lang.max20 }]
                }],
                column: [
                    {
                        label: this.lang.paraClassName,
                        prop: "name",
                        form: { tag: "el-input" }
                    },
                ],
            }
            return [table]
        }
    },
    methods: {
        async tableOnload(page, query) {
            try {
                const p = {
                    pageNum: page.pageNum,
                    pageSize: page.pageSize,
                    deviceTypeId: this.row.id,
                };
                const res = await getDeviceTypeNode(p);
                const list = res.result.data;
                const total = res.result.total || res.result.data.length;
                return { list, total }
            } catch (err) {
                console.log(err);
                return { list: [], total: 0 };
            }
        },
        async handleAdd() {
            this.dialogTitle = this.lang.addPara;
            this.formData = {};
            await this.getNodeClassDict();
            this.dialogVisible = true;
        },
        async handleRowEdit(row) {
            this.formData = row;
            this.dialogTitle = this.lang.editPara;
            await this.getNodeClassDict();
            this.dialogVisible = true;
        },
        handRowDel(row) {
            this.$confirm(this.lang.delMsg, this.lang.tips, {
                confirmButtonText: this.lang.confirm,
                cancelButtonText: this.lang.cancel,
                type: "warning",
            })
                .then(() => {
                    this.deleteDeviceTypeNode(row.id);
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        async handleParamClass() {
            await this.getParamClassData();
            this.classDialogTitle = this.lang.paraClassMain;
            this.classDialogVisible = true;
        },
        async getParamClassData() {
            const res = await getClassDataList();
            const list = res.result.data;
            this.classFormData.class = list;
            this.classFormDataLimit = list.length + 1;
        },
        async deleteDeviceTypeNode(id) {
            try {
                const res = await delDeviceTypeNode(id);
                this.$message.success(res.msg);
                this.$refs.tableRefs.apiOnload();
            } catch (e) {
                this.$refs.tableRefs.apiOnload();
                console.log(e);
            }
        },
        async handleSubmit() {
            try {
                await this.$refs["elsFormRef"].validate();
                let res;
                this.formData.typeId = this.row.id;
                if (this.formData.id) {
                    res = await updateDeviceTypeNode({ ...this.formData });
                } else {
                    res = await addDeviceTypeNode({ ...this.formData });
                }
                this.$message({ type: "success", message: res.msg });
                this.dialogVisible = false;
                this.$refs.tableRefs.apiOnload();
            } catch (err) {
                console.log(err);
            }
        },
        close() {
            this.$emit("close");
        },
        async getNodeClassDict() {
            const res = await getClassDataList();
            this.nodeClassDict = res.result.data.map(item => ({ value: item.typeCode + "_" + item.value, label: item.name }));
        },
        async handleRow(row) {
            try {
                if (row.$delete) {
                    if (row.id) {
                        let key = true;
                        await this.$confirm(this.lang.delMsg, this.lang.tips, {
                            confirmButtonText: this.lang.confirm,
                            cancelButtonText: this.lang.cancel,
                            type: "warning",
                        })
                            .then(() => {
                                 this.deleteParamClass(row.id);
                            })
                            .catch((err) => {
                                key = false;
                            });
                        return key
                    }
                } else {
                    if (!row.name) {
                        row.$edit = true;
                        this.$message.error(this.lang.paraClassNotNull);
                        await this.$refs["classFormRef"].validate();
                        return;
                    }
                    if (row.name.length > 20) {
                        row.$edit = true;
                        this.$message.error(this.lang.paraClassLimit);
                        await this.$refs["classFormRef"].validate();
                        return;
                    }
                    let res;
                    if (row.id) {
                        res = await updateParamClass({ id: row.id, name: row.name, typeCode: "nodeClass" });
                    } else {
                        res = await addParamClass({ name: row.name, typeCode: "nodeClass" })
                    }
                    this.$message({ type: "success", message: res.msg });
                    this.getParamClassData();
                }
                return true
            } catch (err) {
                return false
            }
        },
        async deleteParamClass(id) {
            try {
                const res = await delParamClass(id);
                this.$message({ type: "success", message: res.msg });
            } catch (e) {
                this.getParamClassData()
            }
        }
    },
    created() {
        this.getNodeClassDict();
    }
}
</script>

<style scoped lang='scss'>
.client-myTab4 {
    .client-main {
        margin-top: 20px;

        .footer {
            text-align: right;
            margin-top: 20px;
        }
    }

    ::v-deep .el-button--text:nth-child(3) {
        color: red;
    }
}

::v-deep .el-select {
    width: 100%;
}

.class-dialog-footer {
    text-align: right;
}

::v-deep .t--dict {
    overflow: hidden;
    text-overflow: ellipsis;
}
::v-deep .type-item-table {
    .el-table tbody{
        .el-button--text:nth-child(2) {
            color: red;
         }
    }
}
</style>
